import { Container } from '../components/Container'
import { LocationComboBox } from '../components/common/LocationComboBox'
import { React, useState } from 'react'
import { useAuth } from '../auth/AuthProvider'
import { Grid } from '@interstate/components/Grid'
import { PatTextInput } from '../components/common/PatTextInput'
import { PatInputStyle } from '../components/common/PatInputStyle'
import { ComboBox } from '@interstate/components/ComboBox'
import { Button } from '@interstate/components/Button'
import {
    getVehicleAuctionVin,
    getVehicleAuctionWorkOrder
} from '../service/auctionVehicleDataService'
import { useSaleTypeMappings } from '../service/configService'
import { SaleTypesComboBox } from '../components/common/SaleTypesComboBox'
import { PatWarningAlert } from '../components/common/PatWarningAlert'
import { PatDatePicker } from '../components/common/PatDatePicker'
import {
    getFeeCalculated,
    getPossibleFeeSchedules
} from '../service/feeScheduleService'
import { Table } from '@interstate/components/Table'
import { PatErrorAlert } from '../components/common/PatErrorAlert'
import { PossibleFeeTable } from '../components/tables/PossibleFeeTable'
import { PAT_ROLE } from '../resources/enum.constants'

function firstLocation(locationId) {
    if (locationId && locationId.trim().length > 0) {
        return [locationId?.split(',')[0]]
    }
    return null
}

function dateFormat(date) {
    const dateString = date.dateValue
    const [month, day, year] = dateString.split('/')

    return `${year}${month.padStart(2, '0')}${day.padStart(2, '0')}`
}

export function CalculateFeesView() {
    const authService = useAuth()
    const [locationId, setlocationId] = useState(
        firstLocation(authService?.principal?.locationId) || null
    )

    const { value: saleTypeMappingData } = useSaleTypeMappings(locationId)

    const [searchBy, setSearchBy] = useState([{ label: 'VIN', value: 'vin' }])
    const [searchError, setSearchError] = useState(null)

    const [universalKey, setUniversalKey] = useState(null)
    const [workOrderNumber, setWorkOrderNumber] = useState(null)
    const [vin, setVin] = useState(null)

    const [saleType, setSaleType] = useState(null)
    const [saleDate, setSaleDate] = useState(null)
    const [salePrice, setSalePrice] = useState(null)

    const [buyerAccount, setBuyerAccount] = useState(null)
    const [buyerGroupCode, setBuyerGroupCode] = useState(null)
    const [buyerAltGroupCode, setBuyerAltGroupCode] = useState(null)

    const [sellerAccount, setSellerAccount] = useState(null)
    const [sellerGroupCode, setSellerGroupCode] = useState(null)
    const [sellerAltGroupCode, setSellerAltGroupCode] = useState(null)

    const [channel, setChannel] = useState([
        { label: 'In Lane', value: 'inlane' }
    ])

    const [calculateFeesNotValid, setCalculateFeesNotValid] = useState(null)

    const [calculatedFees, setCalculatedFees] = useState(null)
    const [calculatedFeesError, setCalculatedFeesError] = useState(null)

    const [feeSchedules, setFeeSchedules] = useState(null)
    const [highlightedRow, setHighlightedRow] = useState(null)

    const getSaleType = (lane, table) => {
        const l = Number(lane)
        const t = Number(table)
        let st = saleTypeMappingData?.find((mapping) => {
            if (mapping.lane === l && mapping.feeCategory === t) {
                return mapping.saleType
            }
            return false
        })

        if (!st) {
            st = saleTypeMappingData?.find((mapping) => {
                if (mapping.lane === 0 && mapping.feeCategory === t) {
                    return mapping.saleType
                }
                return false
            })
        } else if (!st) {
            st = {
                saleType: 'ANY'
            }
        }

        return st.saleType
    }

    const handleSearch = () => {
        setCalculateFeesNotValid(null)
        let auctionVehicleData
        if (searchBy[0].value === 'vin') {
            auctionVehicleData = getVehicleAuctionVin(locationId, vin)
        } else if (searchBy[0].value === 'workOrderNumber') {
            auctionVehicleData = getVehicleAuctionWorkOrder(
                locationId,
                workOrderNumber
            )
        }

        console.log('Search')
        auctionVehicleData
            .then((data) => {
                let vehicle = data?.body
                setVin(vehicle?.vin)
                setWorkOrderNumber(vehicle?.workOrder)
                let ukey = `${vehicle?.calendarYear}-${vehicle?.sale}-${vehicle?.lane}-${vehicle?.run}`
                setUniversalKey(ukey)

                //Needs to update on seperate API from mapping
                let st = getSaleType(vehicle?.lane, vehicle?.sellerFeeTable)
                setSaleType(st)

                const year = vehicle?.saleDate.substring(0, 4)
                const month = vehicle?.saleDate.substring(4, 6) - 1 // Months are zero-based in JavaScript
                const day = vehicle?.saleDate.substring(6, 8)
                const date = new Date(year, month, day)
                setSaleDate(date)

                setSalePrice(vehicle?.salePrice)
                setBuyerAccount(vehicle?.buyer)
                setBuyerGroupCode(vehicle?.buyerGroup)
                setBuyerAltGroupCode(vehicle?.buyerSubGroup)
                setSellerAccount(vehicle?.seller)
                setSellerGroupCode(vehicle?.sellerGroup)
                setSellerAltGroupCode(vehicle?.sellerSubGroup)
                setSearchError(null)
            })
            .catch((e) => {
                console.log(e)
                setSearchError('Vehicle not found')
            })
    }

    const handleClear = () => {
        console.log('Clear')
        setVin(null)
        setWorkOrderNumber(null)
        setUniversalKey(null)
        setSaleType(null)
        setSaleDate(null)
        setSalePrice(null)
        setBuyerAccount(null)
        setBuyerGroupCode(null)
        setBuyerAltGroupCode(null)
        setSellerAccount(null)
        setSellerGroupCode(null)
        setSellerGroupCode(null)
        setSearchError(null)
        setCalculateFeesNotValid(null)
    }

    const customerArray = () => {
        let customerIds = []
        if (buyerAccount) {
            customerIds.push(buyerAccount)
        }
        if (buyerGroupCode) {
            customerIds.push(buyerGroupCode)
        }
        if (buyerAltGroupCode) {
            customerIds.push(buyerAltGroupCode)
        }
        if (sellerAccount) {
            customerIds.push(sellerAccount)
        }
        if (sellerGroupCode) {
            customerIds.push(sellerGroupCode)
        }
        if (sellerAltGroupCode) {
            customerIds.push(sellerAltGroupCode)
        }
        if (customerIds.length === 0) {
            customerIds.push('')
        }
        return customerIds
    }

    const handleCalculate = () => {
        setCalculatedFeesError(null)
        let errors = []
        if (!locationId) {
            errors.push('Location')
        }
        if (!saleType) {
            errors.push('Sale Type')
        }
        if (!saleDate) {
            errors.push('Sale Date')
        }
        if (!salePrice) {
            errors.push('Sale Price')
        }
        if (universalKey?.split('-').length !== 4 && universalKey?.length > 0) {
            errors.push(
                'Universal Key must be in the format YYYY-SALE-LANE-RUN'
            )
        }
        if (errors.length > 0) {
            setCalculateFeesNotValid(errors)
            return
        } else {
            setCalculateFeesNotValid(null)
        }

        console.log('Calculate Fees')
        let [syear, ssale, slane, srun] = universalKey
            ? universalKey?.split('-')
            : [0, 0, 0, 0]
        let avd = {
            auctionCode: locationId,
            saleChannel: channel[0].value === 'inlane' ? 'I' : 'Z',
            buyer: buyerAccount,
            buyerGroup: buyerGroupCode,
            // buyerSubGroup: buyerAltGroupCode,
            seller: sellerAccount,
            sellerGroup: sellerGroupCode,
            // sellerSubGroup: sellerAltGroupCode,
            saleType: saleType,
            salePrice: salePrice,
            saleDate: dateFormat(saleDate),
            vin: vin,
            workOrder: workOrderNumber,
            calendarYear: syear,
            sale: ssale,
            lane: slane,
            run: srun
            // othSellerFee1: 0
        }

        getFeeCalculated(avd)
            .then((data) => {
                // console.log(data)
                data = data.filter((fee) => fee.category !== 'SIMBUYAPPLIED')
                if (
                    ![PAT_ROLE.ADMIN, PAT_ROLE.BOC_EDIT].includes(
                        authService?.principal?.role
                    )
                ) {
                    data = data.filter(
                        (fee) => !fee.category.includes('NOCUSTOMER')
                    )
                }

                setCalculatedFees(data)

                let highlight = []
                data?.forEach((fee) => {
                    if (!highlight.includes(fee.feeSchedulePartId)) {
                        highlight.push(fee.feeSchedulePartId)
                    }
                })
                setHighlightedRow(highlight)

                getPossibleFeeSchedules(
                    locationId,
                    customerArray(),
                    saleType,
                    dateFormat(saleDate)
                )
                    .then((d) => {
                        setFeeSchedules(d)
                    })
                    .catch((error) => {
                        setFeeSchedules(error?.response?.data)
                    })
            })
            .catch((e) => {
                console.log(e)
                setCalculatedFeesError('Error calculating fees')
            })
    }

    return (
        <Container>
            <h1>Calculate Fees</h1>

            <form>
                <LocationComboBox
                    name="locationId"
                    value={locationId}
                    onChange={(e) => {
                        setlocationId(e.target?.value[0]?.value)
                    }}
                    auctionEditFilter={true}
                    multiple={false}
                    required={true}
                />

                <Grid container spacing={2}>
                    <Grid xs={12} md={4}>
                        <ComboBox
                            label="Search By"
                            data-testid="searchBy"
                            options={[
                                { label: 'VIN', value: 'vin' },
                                // {
                                //     label: 'Universal Key',
                                //     value: 'universalKey'
                                // },
                                {
                                    label: 'Work Order Number',
                                    value: 'workOrderNumber'
                                }
                            ]}
                            onChange={(e) => {
                                setSearchBy(e.target.value)
                            }}
                            value={searchBy}
                            sx={PatInputStyle}
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        {searchBy[0].value === 'vin' && (
                            <PatTextInput
                                name={'vin'}
                                label="VIN"
                                value={vin}
                                onChange={(e) => {
                                    setVin(e.target.value?.trim())
                                }}
                                minLength={6}
                            />
                        )}
                        {searchBy[0].value === 'universalKey' && (
                            <PatTextInput
                                name={'universalKey'}
                                label="Universal Key"
                                value={universalKey}
                                onChange={(e) => {
                                    setUniversalKey(e.target.value?.trim())
                                }}
                            />
                        )}
                        {searchBy[0].value === 'workOrderNumber' && (
                            <PatTextInput
                                name={'workOrderNumber'}
                                label="Work Order Number"
                                value={workOrderNumber}
                                onChange={(e) => {
                                    setWorkOrderNumber(e.target.value?.trim())
                                }}
                            />
                        )}
                    </Grid>
                    <Grid xs={12} md={4}>
                        <Button
                            onClick={handleSearch}
                            buttonStyle={'primary'}
                            sx={{ marginTop: '20px' }}>
                            Fill Form
                        </Button>
                        <Button
                            onClick={handleClear}
                            buttonStyle={'secondary'}
                            sx={{ marginTop: '20px', marginLeft: '15px' }}>
                            Clear Form
                        </Button>
                        <PatWarningAlert
                            text={searchError}
                            error={searchError}
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <ComboBox
                            label="Channel"
                            data-testid="channel"
                            options={[
                                { label: 'In Lane', value: 'inlane' },
                                {
                                    label: 'Simulcast',
                                    value: 'simulcast'
                                }
                            ]}
                            onChange={(e) => {
                                setChannel(e.target.value)
                            }}
                            value={channel}
                            sx={PatInputStyle}
                        />
                    </Grid>
                    {searchBy[0].value !== 'vin' && (
                        <Grid xs={12} md={4}>
                            <PatTextInput
                                name={'vin'}
                                label="VIN"
                                value={vin}
                                onChange={(e) => {
                                    setVin(e.target.value?.trim())
                                }}
                                minLength={6}
                            />
                        </Grid>
                    )}
                    {searchBy[0].value !== 'universalKey' && (
                        <Grid xs={12} md={4}>
                            <PatTextInput
                                name={'universalKey'}
                                label="Universal Key"
                                value={universalKey}
                                onChange={(e) => {
                                    setUniversalKey(e.target.value?.trim())
                                }}
                            />
                        </Grid>
                    )}
                    {searchBy[0].value !== 'workOrderNumber' && (
                        <Grid xs={12} md={4}>
                            <PatTextInput
                                name={'workOrderNumber'}
                                label="Work Order Number"
                                value={workOrderNumber}
                                onChange={(e) => {
                                    setWorkOrderNumber(e.target.value?.trim())
                                }}
                            />
                        </Grid>
                    )}
                    <Grid xs={12} md={4}>
                        <SaleTypesComboBox
                            name="saleType"
                            value={saleType}
                            onChange={(e) => {
                                setSaleType(e.target.value)
                            }}
                            data-testid={'sale-types-combobox'}
                            required
                            filter={saleTypeMappingData?.map((m) => m.saleType)}
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <PatDatePicker
                            name={'saleDate'}
                            label="Sale Date"
                            value={saleDate}
                            onChange={(e) => {
                                setSaleDate(e.target.value)
                            }}
                            required
                        />
                        {/*<PatTextInput*/}
                        {/*    name={'saleDate'}*/}
                        {/*    label="Sale Date"*/}
                        {/*    value={saleDate}*/}
                        {/*    onChange={(e) => {*/}
                        {/*        setSaleDate(e.target.value?.trim())*/}
                        {/*    }}*/}
                        {/*    required*/}
                        {/*    minLength={6}*/}
                        {/*/>*/}
                    </Grid>
                    <Grid xs={12} md={4}>
                        <PatTextInput
                            name={'salePrice'}
                            label="Sale Price"
                            value={salePrice}
                            onChange={(e) => {
                                setSalePrice(e.target.value?.trim())
                            }}
                            required
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <PatTextInput
                            name={'buyerAccount'}
                            label="Buyer Account"
                            value={buyerAccount}
                            onChange={(e) => {
                                setBuyerAccount(e.target.value?.trim())
                            }}
                            minLength={6}
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <PatTextInput
                            name={'buyerGroupCode'}
                            label="Buyer Group Code"
                            value={buyerGroupCode}
                            onChange={(e) => {
                                setBuyerGroupCode(
                                    e.target.value?.toUpperCase()?.trim()
                                )
                            }}
                            minLength={3}
                            maxLength={4}
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <PatTextInput
                            name={'buyerAltGroupCode'}
                            label="Buyer Alt Group Code"
                            value={buyerAltGroupCode}
                            onChange={(e) => {
                                setBuyerAltGroupCode(
                                    e.target.value?.toUpperCase()?.trim()
                                )
                            }}
                            minLength={3}
                            maxLength={4}
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <PatTextInput
                            name={'sellerAccount'}
                            label="Seller Account"
                            value={sellerAccount}
                            onChange={(e) => {
                                setSellerAccount(e.target.value?.trim())
                            }}
                            minLength={6}
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <PatTextInput
                            name={'sellerGroupCode'}
                            label="Seller Group Code"
                            value={sellerGroupCode}
                            onChange={(e) => {
                                setSellerGroupCode(
                                    e.target.value?.toUpperCase()?.trim()
                                )
                            }}
                            minLength={3}
                            maxLength={4}
                        />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <PatTextInput
                            name={'sellerAltGroupCode'}
                            label="Seller Alt Group Code"
                            value={sellerAltGroupCode}
                            onChange={(e) => {
                                setSellerAltGroupCode(
                                    e.target.value?.toUpperCase()?.trim()
                                )
                            }}
                            minLength={3}
                            maxLength={4}
                        />
                    </Grid>
                </Grid>
                <Button buttonStyle={'primary'} onClick={handleCalculate}>
                    Calculate Fee
                </Button>
                {calculateFeesNotValid && (
                    <PatWarningAlert
                        text={
                            calculateFeesNotValid.join(', ') + ' are required'
                        }
                        error={
                            calculateFeesNotValid.join(', ') + ' are required'
                        }
                    />
                )}
            </form>

            <PatErrorAlert
                text={calculatedFeesError}
                error={calculatedFeesError}
            />
            {calculatedFees && (
                <>
                    <h2>Calculated Fees</h2>
                    <Table
                        columns={[
                            {
                                title: 'Fee Schedule Type',
                                dataIndex: 'contractType'
                            },
                            { title: 'Category', dataIndex: 'category' },
                            { title: 'Fee Type', dataIndex: 'feeType' },
                            { title: 'Fee Amount', dataIndex: 'amount' },
                            {
                                title: 'FeeID',
                                dataIndex: 'feeSchedulePartId'
                            }
                        ]}
                        data={calculatedFees}
                    />
                </>
            )}

            {feeSchedules && (
                <>
                    <h2>Eligible Seller Fees</h2>
                    <PossibleFeeTable
                        fees={feeSchedules}
                        filterCriteria={['SSELL', 'SELLER', 'SLIST']}
                        highlightedRow={highlightedRow}
                    />

                    <h2>Eligible Buyer Fees</h2>
                    <PossibleFeeTable
                        fees={feeSchedules}
                        filterCriteria={[
                            'BUYER',
                            'BLIST',
                            'BBUY',
                            'SELLBUY',
                            'SELLBYS'
                        ]}
                        highlightedRow={highlightedRow}
                    />

                    {channel[0].value === 'simulcast' && (
                        <>
                            <h2>Eligible Simulcast Fees</h2>
                            <PossibleFeeTable
                                fees={feeSchedules}
                                filterCriteria={[
                                    'INTBUY',
                                    'INTSELL',
                                    'SIMBFE',
                                    'SIMBUY'
                                ]}
                                highlightedRow={highlightedRow}
                            />
                        </>
                    )}
                </>
            )}
        </Container>
    )
}
